.pg-interview {

    .ml-interview {
        @extend %row;
        padding-bottom: 3rem;

        .at-page-header {
            @extend %col-xs-12;
            margin-bottom: 1rem;

            .page-title {
                @extend %big-size-2;
                margin-top: 0;
                margin-bottom: .5rem;
            }

            .interview-meta {
                @extend %pilcrow-list;
            }
        }

        .interview-primary {
            @extend %col-md-8, %col-xs-12;

            .ml-item-list-documents {
                > ul {
                    > li {
                        @extend %col-lg-6, %col-md-6, %col-sm-6, %col-xs-12;
                        margin-bottom: 1rem;
                    }
                }
            }

            .ml-item-list-images {
                > ul {
                    > li {
                        @extend %col-lg-6, %col-md-6, %col-sm-6, %col-xs-12;
                        margin-bottom: 2rem;
                    }
                }
            }
        }

        .interview-secondary {
            @extend %col-md-4, %col-xs-12;

            .interview-info {
                margin-bottom: 2rem;

                .interview-info-title {
                    font-size: rem-calc(21);
                    font-family: $body-font-family-sc;
                    margin-top: 0;
                }

                .interview-info-list {

                    dt {
                        font-weight: $font-weight-normal;
                        display: inline;

                        &:before {
                            content: '¶';
                            display: inline-block;
                            color: tint($primary-color, 30);
                            margin-right: .35rem;
                        }

                        &:first-child {
                            &:before {
                                display: none;
                            }
                        }
                    }

                    dd {
                        font-weight: $font-weight-bold;
                        display: inline;
                        margin-right: .25rem;
                    }
                }

                .interview-tags-list {
                    dt {
                        &:before {
                            content: '\A';
                            white-space: pre;
                        }

                        &:first-of-type {
                            &:before {
                                display: none;
                            }
                        }
                    }

                    dd {
                        margin-right: .25rem;

                        &:before {
                            content: '¶';
                            display: inline-block;
                            color: tint($primary-color, 30);
                            margin-right: .35rem;
                        }
                    }

                    dt + dd {
                        &:before {
                            display: none;
                        }
                    }
                }

                .interview-info-link {
                    font-style: italic;
                    @extend %icon-arrow-long-right, %icon-spacing-after;
                }
            }
        }

        .fragments-summary {
            margin-bottom: 2rem;
        }
    }

}