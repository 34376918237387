@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon.eot?v08scs');
    src:    url('../fonts/icomoon.eot?v08scs#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?v08scs') format('truetype'),
        url('../fonts/icomoon.woff?v08scs') format('woff'),
        url('../fonts/icomoon.svg?v08scs#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

%icon {
    &:before,
    &:after{
        /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
}

$icon-var-arrow_forward: "\e909";
%icon-arrow_forward {
    @extend %icon;
    &:before {
    content: $icon-var-arrow_forward;
    }
}
$icon-var-arrow_back: "\e90a";
%icon-arrow_back {
    @extend %icon;
    &:before {
    content: $icon-var-arrow_back;
    }
}
$icon-var-gear: "\e908";
%icon-gear {
    @extend %icon;
    &:before {
    content: $icon-var-gear;
    }
}
$icon-var-cross: "\e903";
%icon-cross {
    @extend %icon;
    &:before {
    content: $icon-var-cross;
    }
}
$icon-var-switch: "\e907";
%icon-switch {
    @extend %icon;
    &:before {
    content: $icon-var-switch;
    }
}
$icon-var-doc: "\e904";
%icon-doc {
    @extend %icon;
    &:before {
    content: $icon-var-doc;
    }
}
$icon-var-file: "\e905";
%icon-file {
    @extend %icon;
    &:before {
    content: $icon-var-file;
    }
}
$icon-var-pdf: "\e906";
%icon-pdf {
    @extend %icon;
    &:before {
    content: $icon-var-pdf;
    }
}
$icon-var-arrow-long-left: "\e900";
%icon-arrow-long-left {
    @extend %icon;
    &:before {
    content: $icon-var-arrow-long-left;
    }
}
$icon-var-arrow-long-right: "\e901";
%icon-arrow-long-right {
    @extend %icon;
    &:before {
    content: $icon-var-arrow-long-right;
    }
}
$icon-var-menu: "\e902";
%icon-menu {
    @extend %icon;
    &:before {
    content: $icon-var-menu;
    }
}

