.at-audio {
    margin-bottom: 2rem;
    font-size: 1.2rem;
    
    audio {
        width: 100%;
    }
}

.tab-content {
    
    .at-audio {
        border-bottom: 1px solid $body-bg;
        padding-bottom: 2rem;
        
        &:last-of-type {
            border-bottom: none;
        }
    }
}