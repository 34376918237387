.at-tags-list {
    @extend %inline-list;
    margin-top: 1rem;
    
    li {
        margin-right: 0;
        
        a {
            @extend %tag;
            text-decoration: none;
        }
        
        label {
            @extend %tag;
            margin-left: 0;
            
            &:before {
                display: none;
            }
        }
        
        input:checked + label:before {
            display: none;
        }
        
        input:checked + label {
            color: $light-green;
            background-color: $dark-green;
        }
    }
}