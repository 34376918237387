.at-search-form {
    input {
        &:focus {
            outline: none;
        }
    }

    .form-group.is-submit {
        flex-grow: 0;
    }

    &.has-background {
        background-color: $secondary-color;
        padding: 2rem 2.5rem 1.5rem;
    }
}
