.at-submenu {
    @extend %unstyled-list;
    font-family: $body-font-family-sc;
    margin-top: 1rem;
    
    li {
        text-align: center;
        
        a {
            color: $body-bg;
            text-decoration: none;
            font-size: rem-calc(20);
            border-right: 1px solid $body-bg;
            border-bottom: 1px solid $body-bg;
            display: block;
            padding: 1rem;
            background-color: tint($primary-color, 80);
            margin-left: -1px;
            border-left: 1px solid $body-bg;
            
            &.is-active {
                border-left: 1px solid $secondary-color;
                color: $primary-color;
                background-color: $secondary-color;
            }
            
            &:hover {
                color: $primary-color;
            }
        }
        
        &:first-of-type {
            a {
                border-top: 1px solid $body-bg;
            }
        }
    }
    
    @media screen and (max-width: $viewport-md) {
        
        letter-spacing: -.20rem;
        border-bottom: 1px solid $body-bg;
        
        li {
            display: inline-block;
            letter-spacing: normal;
            
            a {
                border-bottom: 1px solid $body-bg;
                margin-left: 0;
                border-left: none;
                margin-bottom: -1px;
                border-top: 1px solid $body-bg;
                font-size: 1rem;
                padding: .5rem .75rem;
                
                &.is-active {
                    border-bottom: 1px solid $secondary-color;
                }
            }
            
            &:first-of-type {
                
                a {
                    border-left: 1px solid $body-bg;
                }
            }
        }
    }
}