.ml-site-header {
    .logo-menu-wrapper {
        @extend %row;
        padding-top: 2rem;
        padding-bottom: 2rem;

        .logo-wrapper {
            @extend %col-lg-3, %col-md-3;

            img {
                max-width: 260px;
            }
        }

        .at-menu {
            @extend %col-lg-8, %col-lg-offset-1, %col-md-9;
            margin-top: .5rem;
        }

        @media screen and (max-width: $viewport-sm) {

            .logo-wrapper {
                img {
                    width: 180px;
                }
            }
        }
    }
}