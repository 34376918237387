// We use these to make sure border radius matches unless we want it different.
// $global-radius: 3px;
// $global-rounded: 1000px;

// We use these to control inset shadow shiny edges and depressions.
// $shiny-edge-size: 0 1px 0;
// $shiny-edge-color: rgba($white, .5);
// $shiny-edge-active-color: rgba($black, .2);

// We use these to define default font stacks
$body-font-family: 'Alegreya', serif;
$body-font-family-sc: 'Alegreya SC', serif;
$font-family-serif: $body-font-family;
$font-family-monospace: Consolas, "Liberation Mono", Courier, monospace;

// We use this as cursors values for enabling the option of having custom cursors in the whole site's stylesheet
// $cursor-crosshair-value: crosshair;
// $cursor-default-value: default;
// $cursor-pointer-value: pointer;
// $cursor-help-value: help;
// $cursor-text-value: text;

@import "../../web_modules/foundation/scss/foundation/components/global";

%clearfix {
    @include clearfix;
}

@mixin triangle ($size, $color, $direction) {
    height: 0;
    width: 0;

    $width: nth($size, 1);
    $height: nth($size, length($size));

    $foreground-color: nth($color, 1);
    $background-color: if(length($color) == 2, nth($color, 2), transparent);

    @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {

        $width: $width / 2;
        $height: if(length($size) > 1, $height, $height/2);

        @if $direction == up {
            border-left: $width solid $background-color;
            border-right: $width solid $background-color;
            border-bottom: $height solid $foreground-color;

        } @else if $direction == right {
            border-top: $width solid $background-color;
            border-bottom: $width solid $background-color;
            border-left: $height solid $foreground-color;

        } @else if $direction == down {
            border-left: $width solid $background-color;
            border-right: $width solid $background-color;
            border-top: $height solid $foreground-color;

        } @else if $direction == left {
            border-top: $width solid $background-color;
            border-bottom: $width solid $background-color;
            border-right: $height solid $foreground-color;
        }
    }

    @else if ($direction == up-right) or ($direction == up-left) {
        border-top: $height solid $foreground-color;

        @if $direction == up-right {
            border-left:  $width solid $background-color;

        } @else if $direction == up-left {
            border-right: $width solid $background-color;
        }
    }

    @else if ($direction == down-right) or ($direction == down-left) {
        border-bottom: $height solid $foreground-color;

        @if $direction == down-right {
            border-left:  $width solid $background-color;

        } @else if $direction == down-left {
            border-right: $width solid $background-color;
        }
    }

    @else if ($direction == inset-up) {
        border-width: $height $width;
        border-style: solid;
        border-color: $background-color $background-color $foreground-color;
    }

    @else if ($direction == inset-down) {
        border-width: $height $width;
        border-style: solid;
        border-color: $foreground-color $background-color $background-color;
    }

    @else if ($direction == inset-right) {
        border-width: $width $height;
        border-style: solid;
        border-color: $background-color $background-color $background-color $foreground-color;
    }

    @else if ($direction == inset-left) {
        border-width: $width $height;
        border-style: solid;
        border-color: $background-color $foreground-color $background-color $background-color;
    }
}