.tp-static {
    
    .content-wrapper {
        padding-left: 9rem;
        padding-right: 9rem;
        
        .at-page-header {
            margin-bottom: 2rem;
            
            .page-header-title {
                font-size: rem-calc(48);
            }
            
            .page-header-description {
                font-size: rem-calc(22);
                line-height: 2rem;
            }
            
            @media screen and (max-width: $viewport-md) {
                
                margin-bottom: 1rem;
                
                .page-header-title {
                    font-size: rem-calc(36);
                }
                
                .page-header-description {
                    font-size: rem-calc(20);
                    line-height: 1.8rem;
                }
            }
            
            @media screen and (max-width: $viewport-xs) {
                
                .page-header-description {
                    font-size: rem-calc(16);
                    line-height: 1.5rem;
                }
            }
        }
        
        .columns-wrapper {
            @extend %row;
            margin-bottom: 2rem;
            
            .primary-wrapper {
                @extend %col-md-9, %col-xs-12;
                font-size: 1.2rem;
                
                @media screen and (max-width: $viewport-md) {
                    order: 2;
                    font-size: 1rem;
                }
            }
            
            .secondary-wrapper {
                @extend %col-md-3, %col-xs-12;
                border-left: 1px solid $body-bg;
                padding-left: 0;
                
                @media screen and (max-width: $viewport-md) {
                    order: 1;
                    border-left: none;
                    padding-left: 1rem;
                }
            }
        }
        
        @media screen and (max-width: $viewport-lg) {
            padding-left: 2rem;
            padding-right: 2rem;
        }
        
        @media screen and (max-width: $viewport-xs) {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }
}
