.ml-tabs {

    .tab-index {
        @extend %inline-list;
        border-bottom: 1px solid $body-bg;
        letter-spacing: -.34rem;

        li {
            margin-right: 0;
            letter-spacing: normal;

            a, input[type="radio"] + label {
                color: $body-bg;
                font-family: $body-font-family-sc;
                text-decoration: none;
                font-size: rem-calc(21);
                padding: .25rem 1rem;
                background-color: tint($primary-color, 80);
                display: block;
                border-top: 1px solid $body-bg;
                border-right: 1px solid $body-bg;
                position: relative;
                top: 1px;
                margin-left: 0;
                margin-right: 0;

                &.is-active {
                    color: $primary-color;
                    background-color: $secondary-color;
                    border-bottom: 1px solid $secondary-color;
                    position: relative;
                }

                &:hover {
                    color: $primary-color;
                }

                &::before {
                    display: none;
                }
            }

            input[type="radio"]:checked {
                + label {
                    color: $primary-color;
                    background-color: $secondary-color;
                    border-bottom: 1px solid $secondary-color;
                    position: relative;
                }
            }

            &:first-of-type {
                a {
                    border-left: 1px solid $body-bg;
                }
            }
        }

        @media screen and (max-width: $viewport-md) {

            li {
                a {
                    font-size: 1rem;
                    padding: .5rem .75rem;
                }
            }

        }
    }

    .tab-content {
        display: none;
        padding-top: .5rem;

        &.is-active {
            display: block;
        }

        &#resumo {
            font-size: 1.2rem;

            p {

                &:first-of-type:first-letter {
                    float: left;
                    font-size: 6rem;
                    font-weight: 800;
                    margin-right: 1rem;
                    line-height: 6rem;
                    color: $body-bg;
                }
            }
        }
    }
}