.at-menu {

    ul {
        @extend %inline-list;
        font-family: $body-font-family-sc;
        text-align: right;
        font-size: rem-calc(21);

        li {
            margin-right: 1.5rem;

            a {
                color: $secondary-color;
                text-decoration: none;

                &:hover {
                    color: $light-green;
                }

                &.is-active {
                    color: $primary-color;
                }
            }
        }
    }

    .menu-responsive {
        display: none;
        @extend %icon-menu;
        font-size: 2rem;
        color: $secondary-color;
        z-index: 999;
    }

    @media screen and (max-width: $viewport-md) {

        .menu-responsive {
            display: block;
            position: absolute;
            right: 2rem;
            top: 4rem;
        }

        ul {
            position: fixed;
            width: 240px;
            z-index: 99;
            right: -240px;
            height: 100%;
            top: 0;
            padding-top: 2.75rem;
            transition: all .5s ease;
            background-color: $primary-color;

            li {
                display: block;
                margin-right: 0;
                text-align: center;
                width: 100%;
                margin-bottom: 1rem;
                border-bottom: 1px solid $body-bg;
                padding-bottom: 1rem;

                &:first-of-type {
                    border-top: 1px solid $body-bg;
                    padding-top: 1rem;
                }

                a {
                    font-size: 1.4rem;
                    display: block;
                    font-weight: $font-weight-bold;
                    padding-left: .5rem;
                    padding-right: .5rem;
                    line-height: 1.6rem;

                    &:hover {
                        color: $light-green;
                    }
                }
            }
        }


        &.is-fixed {
            top: 0;

            .menu-responsive {
                color: $secondary-color;
                position: absolute;
                transition: all .5s ease;

                &:before {
                    content: $icon-var-cross;
                }
            }

            ul {
                right: 0;
                transition: all .5s ease;
            }

            @at-root {

                body {
                    position: relative;
                    transition: all .5s ease;
                    left: 0;

                    &.is-moved {
                        left: -240px;
                    }
                }
            }

        }
    }

    @media screen and (max-width: $viewport-xs) {
        .menu-responsive {
            right: 1rem;
        }
    }

}