%tag {
    font-size: 1rem;
    line-height: 2em;
    color: $dark-green;
    display: inline-block;
    padding: 0.3rem .75rem;
    background: $light-green;
    margin-bottom: .5rem;
    height: 2.5625rem;
    font-weight: $font-weight-bold;
    margin-right: 0;

    &:hover {
        background: tint($light-green, 30);
    }
}