.at-section-filter {
    @extend %row;
    max-height: 80rem;
    overflow: hidden;
    opacity: 1;
    transition: all .5s;

    .section-filter-themes {
        @extend %col-lg-7, %col-md-7, %col-sm-12, %col-xs-12;
    }

    .section-filter-location {
        @extend %col-lg-5, %col-md-5, %col-sm-5, %col-xs-12;
    }

    .section-filter-sources,
    .section-filter-multimedia {
        @extend %col-lg-4, %col-md-4, %col-sm-7, %col-xs-12;
    }

    .section-filter-years,
    .section-filter-image-types {
        @extend %col-lg-3, %col-md-3, %col-sm-4, %col-xs-12;

        .form-group {

            &:first-of-type {
                label {
                    text-align: left;
                }
            }
        }

        @media screen and (max-width: $viewport-sm) {

            .form-group-wrapper {
                .form-group {
                    min-width: inherit;

                    &:first-of-type {
                        label {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }

    .section-filter-eras {
        @extend %col-lg-5, %col-md-5, %col-sm-8, %col-xs-12;
    }

    .section-filter-header {
        .section-filter-title {
            margin-bottom: 0;
            font-size: rem-calc(25);
        }
    }

    .form-group-wrapper {
        padding: 1rem;
        align-items: baseline;

        .form-group {
            padding-right: .25rem;
            width: 100%;

            select {
                font-size: rem-calc(16);
                margin-bottom: .5rem;
            }
        }

        @media screen and (max-width: $viewport-sm) {

            .form-group {
                min-width: 0;
                width: auto !important;
            }
        }
    }

    &.is-folded {
        max-height: 0;
        opacity: 0;
        transform: all .5s;
    }
}