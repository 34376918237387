.at-search-results {

    .search-results-number {
        font-style: italic;
        text-align: center;
        font-size: rem-calc(22);
        margin-bottom: 1.5rem;
        
        span {
            font-weight: $font-weight-bold;
        }
        
        .show-filters {
            font-weight: $font-weight-normal;
            color: $dark-green;
            text-decoration: underline;
            cursor: pointer;
            
            &:hover {
                text-decoration: none;
            }
            
            &:before {
                content: '¶';
                display: inline-block;
                color: tint($primary-color, 30);
                margin-right: .35rem;
            }
        }
    }
    
    > ul {
        @extend %unstyled-list;
        
        > li {
            padding-bottom: .75rem;
            padding-bottom: .75rem;
            border-bottom: 1px solid $body-bg;
            margin-left: -2rem;
            margin-right: -2rem;
            padding-left: 2rem;
            padding-right: 2rem;
            
            a {
                text-decoration: none;
                color: $primary-color;
                display: block;
            }
            
            &:last-of-type {
                border-bottom: none;
            }
            
            &:hover {
                background-color: tint($secondary-color, 60);
            }
            
            @media screen and (max-width: $viewport-sm) {
                margin-left: 0;
                margin-right: 0;
                padding-left: 0;
                padding-right: 0;
                
                &:hover {
                    background-color: inherit;
                }
            }
        }
    }
    
    @media screen and (max-width: $viewport-sm) {
        
        .search-results-number {
            margin-top: 2rem;
            
            .show-filters {
                display: block;
                margin: .5rem 0;
                
                &:before {
                    display: none;
                }
            }
        }
        
    }
}