.at-interview-summary {
    
    .interview-summary-header {
        font-size: 1.2rem;
        
        .interview-title {
            font-size: 1.2rem;
            margin-bottom: .5rem;
            color: $dark-green;
            font-weight: $font-weight-bold;
            margin-top: 1.3rem;
        }
        
        .interview-person {
        
            .interview-name {
                margin-bottom: 0;
                font-size: 1.2rem;
            }
            
            .interview-meta {
                @extend %pilcrow-list;
            }
        }
    }
    
    .interview-summary-section {
        font-size: 1.2rem;
        
        .interview-section-title {
            margin-top: 1.3rem;
            font-size: 1.2rem;
        }
        
        .interview-section-body {
            margin-bottom: 1rem;
        }
    }
    
    @media screen and (max-width: $viewport-sm) {
        
        .interview-summary-section {
            
            .interview-section-title {
                margin-top: 0;
            }
        }
    }
}