.pg-register {
    
    .ml-form {
        
        .form-group-wrapper {
            
            .form-group {
                @extend %col-sm-6;
                
                label {
                    margin-bottom: .5rem;
                    font-style: italic;
                    font-size: 1.2rem;
                }
            }
            
            &.textarea-wrapper {
                .form-group {
                    @extend %col-xs-12;
                }
            }
            
            &.send-wrapper {
                
                .form-group {
                    
                    label {
                        &:before {
                            margin-top: .15rem;
                        }
                    }
                    
                    &:last-of-type {
                        text-align: right;
                    }
                }
            }
        }
    }
}