.at-pagination {
    @extend %unstyled-list;
    @extend %inline-list;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;

    li {
        margin: 0 0.1rem;

        a,
        .button {
            padding: 0.4rem 1rem;
            display: block;
            color:$primary-color;
            background: white;
            font-size: 1.2rem;
            font-weight: $font-weight-bold;
            text-decoration: none;

            &:hover {
                background: $body-bg;
                color: white;
                text-decoration: none;
            }
        }

        &.is-unavailable {
            display: none;
            
            a,
            .button {
                background: $body-bg;
                color: white;

            }
        }
        
        &.is-current {
            a,
            .button {
                background: $primary-color;
                color: white;
            }
        }
    }

    .next, .previous {
        background: $primary-color;
        padding: 0.4rem 0.8rem;
        text-decoration: none;
        color: white;

        &:hover {
            border: 1px solid $gray5;
            background: $gray5;
        }
    }
    
    .action-last {
        @extend %icon-arrow_forward;
    }
    
    .action-first {
        @extend %icon-arrow_back;
    }
}