.at-document-summary {
    position: relative;
    
    &:before {
        position: absolute;
        font-size: 3rem;
        color: $body-bg;
    }
    
    &.ico-pdf {
        @extend %icon-pdf;
    }
    
    &.ico-doc {
        @extend %icon-doc;
    }
    
    &.ico-file {
        @extend %icon-file;
    }
    
    .document-summary-header {
        padding-left: 3.8rem;
        
        .document-summary-title {
            font-size: rem-calc(18);
            line-height: 1.4rem;
            margin-bottom: .5rem;
        }
        
        .document-summary-link {
            font-style: italic;
        }
    }
}