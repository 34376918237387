.at-image-summary {
    @extend %row;

    .image-summary-header {
        @extend %col-md-10, %col-xs-8;
        font-size: 1.2rem;

        .image-title {
            font-size: 1.2rem;
            margin-bottom: .5rem;
            color: $dark-green;
            font-weight: $font-weight-bold;
            margin-top: 1.3rem;
        }

        .image-info {

            .image-name {
                margin-bottom: 0;
                font-size: 1.2rem;
            }

            .image-meta {
                @extend %pilcrow-list;
            }
        }
    }

    .image-summary-section {
        font-size: 1.2rem;

        .image-section-title {
            margin-top: 1.3rem;
            font-size: 1.2rem;
        }

        .image-section-body {
            margin-bottom: 1rem;
        }
    }

    .image-figure {
        @extend %col-md-2, %col-xs-4;
        margin: 1.3rem 0 .5rem;
    }

    @media screen and (max-width: $viewport-sm) {

        .image-summary-section {

            .image-section-title {
                margin-top: 0;
            }
        }
    }
}