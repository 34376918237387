// ########  #######  ##    ## ########    ##      ## ######## ####  ######   ##     ## ########  ######
// ##       ##     ## ###   ##    ##       ##  ##  ## ##        ##  ##    ##  ##     ##    ##    ##    ##
// ##       ##     ## ####  ##    ##       ##  ##  ## ##        ##  ##        ##     ##    ##    ##
// ######   ##     ## ## ## ##    ##       ##  ##  ## ######    ##  ##   #### #########    ##     ######
// ##       ##     ## ##  ####    ##       ##  ##  ## ##        ##  ##    ##  ##     ##    ##          ##
// ##       ##     ## ##   ###    ##       ##  ##  ## ##        ##  ##    ##  ##     ##    ##    ##    ##
// ##        #######  ##    ##    ##        ###  ###  ######## ####  ######   ##     ##    ##     ######

$font-weight-normal: 400;
$font-weight-bold: 700;


// ########  ######## ########    ###    ##     ## ##       ########    ########  #######  ##    ## ########  ######
// ##     ## ##       ##         ## ##   ##     ## ##          ##       ##       ##     ## ###   ##    ##    ##    ##
// ##     ## ##       ##        ##   ##  ##     ## ##          ##       ##       ##     ## ####  ##    ##    ##
// ##     ## ######   ######   ##     ## ##     ## ##          ##       ######   ##     ## ## ## ##    ##     ######
// ##     ## ##       ##       ######### ##     ## ##          ##       ##       ##     ## ##  ####    ##          ##
// ##     ## ##       ##       ##     ## ##     ## ##          ##       ##       ##     ## ##   ###    ##    ##    ##
// ########  ######## ##       ##     ##  #######  ########    ##       ##        #######  ##    ##    ##     ######
//
$body-font-family: $font-family-serif; //Font family stacks are located in utilities/global because they need to be set earlier
$body-font-weight: $font-weight-normal;
$body-font-style: normal;


// ##     ## ########    ###    ########  ######## ########     ########  #######  ##    ## ########  ######
// ##     ## ##         ## ##   ##     ## ##       ##     ##    ##       ##     ## ###   ##    ##    ##    ##
// ##     ## ##        ##   ##  ##     ## ##       ##     ##    ##       ##     ## ####  ##    ##    ##
// ######### ######   ##     ## ##     ## ######   ########     ######   ##     ## ## ## ##    ##     ######
// ##     ## ##       ######### ##     ## ##       ##   ##      ##       ##     ## ##  ####    ##          ##
// ##     ## ##       ##     ## ##     ## ##       ##    ##     ##       ##     ## ##   ###    ##    ##    ##
// ##     ## ######## ##     ## ########  ######## ##     ##    ##        #######  ##    ##    ##     ######

$header-font-family: $font-family-serif;
$header-font-weight: $font-weight-bold;
$header-font-style: normal;
$header-font-color: $primary-color;
$header-line-height: 1.4;
$header-top-margin: .2rem;
$header-bottom-margin: .5rem;

// We use these to control header font sizes
$h1-font-size: rem-calc(25);
$h2-font-size: rem-calc(22);
$h3-font-size: rem-calc(20);
$h4-font-size: rem-calc(18);
$h5-font-size: rem-calc(16);
$h6-font-size: .9rem;


// ######## ##     ## ######## ########     ###        ######  #### ######## ########  ######
// ##        ##   ##     ##    ##     ##   ## ##      ##    ##  ##       ##  ##       ##    ##
// ##         ## ##      ##    ##     ##  ##   ##     ##        ##      ##   ##       ##
// ######      ###       ##    ########  ##     ##     ######   ##     ##    ######    ######
// ##         ## ##      ##    ##   ##   #########          ##  ##    ##     ##             ##
// ##        ##   ##     ##    ##    ##  ##     ##    ##    ##  ##   ##      ##       ##    ##
// ######## ##     ##    ##    ##     ## ##     ##     ######  #### ######## ########  ######


$big-font-size-1: rem-calc(55);
$big-font-size-2: rem-calc(36);
$big-font-size-3: rem-calc(30);


$small-font-size: rem-calc(11);

// ########     ###    ########     ###     ######   ########     ###    ########  ##     ##  ######
// ##     ##   ## ##   ##     ##   ## ##   ##    ##  ##     ##   ## ##   ##     ## ##     ## ##    ##
// ##     ##  ##   ##  ##     ##  ##   ##  ##        ##     ##  ##   ##  ##     ## ##     ## ##
// ########  ##     ## ########  ##     ## ##   #### ########  ##     ## ########  #########  ######
// ##        ######### ##   ##   ######### ##    ##  ##   ##   ######### ##        ##     ##       ##
// ##        ##     ## ##    ##  ##     ## ##    ##  ##    ##  ##     ## ##        ##     ## ##    ##
// ##        ##     ## ##     ## ##     ##  ######   ##     ## ##     ## ##        ##     ##  ######
$paragraph-font-family: $body-font-family;
$paragraph-font-weight: $font-weight-normal;
$paragraph-font-size: .9rem;
$paragraph-line-height: 1.4em;
$paragraph-margin-bottom: 1rem;


// ##       #### ##    ## ##    ##  ######
// ##        ##  ###   ## ##   ##  ##    ##
// ##        ##  ####  ## ##  ##   ##
// ##        ##  ## ## ## #####     ######
// ##        ##  ##  #### ##  ##         ##
// ##        ##  ##   ### ##   ##  ##    ##
// ######## #### ##    ## ##    ##  ######
$anchor-font-color: $secondary-color;
$anchor-font-color-hover: $primary-color;


.page-wrapper {
    // We keep browser default (16px) for rem calculations
    // but set smaller default font for elements inside the page
    font-size: $paragraph-font-size;
}

%big-size-1 {
    font-size: $big-font-size-1;
    letter-spacing: -0.015em;
    line-height: 1.1em;

    @media screen and (max-width: $viewport-md) {
        font-size: $big-font-size-1 / 1.2;
    }
    
    @media screen and (max-width: $viewport-xs) {
        font-size: $big-font-size-1 / 2;
    }
}

%big-size-2 {
    font-size: $big-font-size-2;
    letter-spacing: -0.015em;
    line-height: 1.1em;
    @media screen and (max-width: $viewport-md) {
        font-size: $big-font-size-2 / 1.2;
    }
}

%big-size-3 {
    font-size: $big-font-size-3;
    line-height: 1.1em;
    @media screen and (max-width: $viewport-md) {
        font-size: $big-font-size-3 / 1.2;
    }
}

%body-size-1 {
    font-size: $paragraph-font-size + 0.1;
    line-height: 1.5em;
}
%body-size-2 {
    font-size: $paragraph-font-size;
    line-height: $paragraph-line-height;
}
%body-size-3 {
    font-size: $paragraph-font-size - 0.1;
    line-height: $paragraph-line-height - 0.25;
}

blockquote {
    position: relative;
    padding-left: 1rem;
    &:before {
        content: "\201C";
        font-size: 4em;
        color: $primary-color;
        position: absolute;
        left: -1rem;
        top: 1rem;
    }
}

p,
ul,
ol {
    margin: 0 0 $paragraph-margin-bottom;
}

//      ##   ## #######   ##   #####  #### ##   ##   ####   #####
//      ##   ##  ##   #  ####   ## ##  ##  ###  ##  ##  ## ##   ##
//      ##   ##  ## #   ##  ##  ##  ## ##  #### ## ##      ##
//      #######  ####   ##  ##  ##  ## ##  ## #### ##       #####
//      ##   ##  ## #   ######  ##  ## ##  ##  ### ##  ###      ##
//      ##   ##  ##   # ##  ##  ## ##  ##  ##   ##  ##  ## ##   ##
//      ##   ## ####### ##  ## #####  #### ##   ##   #####  #####
//
%h1-size {
    font-size: $h1-font-size;
    line-height: 1.1em;
}
%h2-size {
    font-size: $h2-font-size;
    line-height: 1.1em;
}
%h3-size {
    font-size: $h3-font-size;
    line-height: 1.3em;
}
%h4-size {
    font-size: $h4-font-size;
    line-height: 1.2em;
}
%h5-size {
    font-size: $h5-font-size;
    line-height: 1.3em;
}
%h6-size {
    font-size: $h6-font-size;
    line-height: 1.3em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $header-font-weight;
    font-family: $header-font-family;
}

h1 { @extend %h1-size; }
h2 { @extend %h2-size; }
h3 { @extend %h3-size; }
h4 { @extend %h4-size; }
h5 { @extend %h5-size; }
h6 { @extend %h6-size; }


.lead {
    @extend %h4-size;
}

%main-subtitle {
    font-size: 1rem;
    font-weight: $font-weight-normal;
    font-family: $header-font-family;
    text-transform: uppercase;
}

%subtitle {
    font-family: $header-font-family;
    font-weight: $font-weight-normal;
    text-transform: uppercase;
}

%title-with-lines {
    position: relative;
    display: inline-block;
    &:before,
    &:after {
        content: "";
        display: block;
        width: 1.25rem;
        border-bottom: 1px solid;
        height: 0;
        position: absolute;
        top: 50%;
    }
    &:before {
        left: -2rem;
    }
    &:after {
        right: -2rem;
    }
}

//      ####    #### ##   ## ###  ##  #####
//       ##      ##  ###  ##  ##  ## ##   ##
//       ##      ##  #### ##  ## ##  ##
//       ##      ##  ## ####  ####    #####
//       ##   #  ##  ##  ###  ## ##       ##
//       ##  ##  ##  ##   ##  ##  ## ##   ##
//      ####### #### ##   ## ###  ##  #####
//


%primary-link {
    text-decoration: none;
    color: $anchor-font-color;
    &:hover,
    &:focus,
    &:active {
        color: $anchor-font-color-hover;
    }
}

%default-link {
    text-decoration: underline;
    color: $dark-green;
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
}

%dull-link {
    text-decoration: none;
    color: $body-font-color;
}

a {
   @extend %default-link;
}

:root {
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}