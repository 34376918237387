.pg-newspaper-archive-number {

    .ml-newspaper-number {
        @extend %row;
        padding-bottom: 3rem;

        .at-page-header {
            @extend %col-xs-12;
            margin-bottom: 1rem;

            .page-title {
                @extend %big-size-2;
                margin-top: 0;
                margin-bottom: .5rem;
            }

            .newspaper-number-meta {
                @extend %pilcrow-list;
            }
        }

        .ml-item-list-images {
            padding: 0 1rem;

            > ul {
                > li {
                    @extend %col-lg-3, %col-md-3, %col-sm-6, %col-xs-6;
                    margin-bottom: 2rem;
                }
            }
        }
    }

}