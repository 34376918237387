.pg-newspaper-archive-index {
    .at-page-header {
        padding-left: 8rem;
        padding-right: 8rem;
        text-align: center;
        margin-top: 4rem;
        margin-bottom: 4rem;
        color: $secondary-color;

        .page-header-title {
            font-size: rem-calc(60);
        }

        .page-header-subtitle {
            font-size: rem-calc(30);
            line-height: rem-calc(38);
        }

        @media screen and (max-width: $viewport-lg) {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        @media screen and (max-width: $viewport-md) {
            margin-top: 2rem;

            .page-header-title {
                font-size: rem-calc(36);
            }

            .page-header-subtitle {
                font-size: rem-calc(24);
                line-height: rem-calc(30);
            }
        }

        @media screen and (max-width: $viewport-sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }


    .at-search-form {
        .row {
            @extend %row;
        }

        .newspaper-col {
            @extend %col-lg-3, %col-md-3, %col-xs-12;
        }

        .place-col {
            @extend %col-lg-3, %col-md-3, %col-xs-12;
        }

        .from-col,
        .to-col {
            @extend %col-lg-2, %col-md-2, %col-xs-6;
            display: flex;

            label {
                width: 30%;
                padding-top: .5rem;
                text-align: right;
                margin-right: 1rem;
            }

            select {
                width: 70%;
            }
        }

        .submit-col {
            @extend %col-lg-2, %col-md-2, %col-xs-12;

            button {
                width: 100%;
            }
        }
    }

    .at-search-results {

    }
}