.pg-homepage {

    .ml-site-header {

        .at-menu {
            ul {
                li {
                    a {
                        color: $secondary-color;
                    }
                }
            }
        }
    }

    .at-page-header {
        padding-left: 8rem;
        padding-right: 8rem;
        text-align: center;
        margin-top: 4rem;
        margin-bottom: 4rem;
        color: $secondary-color;

        .page-header-title {
            font-size: rem-calc(60);
        }

        .page-header-subtitle {
            font-size: rem-calc(30);
            line-height: rem-calc(38);
        }

        @media screen and (max-width: $viewport-lg) {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        @media screen and (max-width: $viewport-md) {
            margin-top: 2rem;

            .page-header-title {
                font-size: rem-calc(36);
            }

            .page-header-subtitle {
                font-size: rem-calc(24);
                line-height: rem-calc(30);
            }
        }

        @media screen and (max-width: $viewport-sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .at-search-form {
        margin-bottom: 4rem;
        margin-left: 8rem;
        margin-right: 8rem;

        .form-group-wrapper {
            margin-left: 0;
            margin-right: 0;
        }

        @media screen and (max-width: $viewport-lg) {
            margin-left: 2rem;
            margin-right: 2rem;
        }

        @media screen and (max-width: $viewport-sm) {
            margin-left: 0;
            margin-right: 0;

            .form-group {
                display: inline-block;
                width: auto;
                min-width: inherit;
            }
        }

    }

    .at-search-results {

        .at-interview-summary {
            @extend %row;

            .interview-summary-header {
                @extend %col-lg-5, %col-md-5, %col-sm-5, %col-xs-12;
            }

            .interview-summary-section {
                @extend %col-lg-7, %col-md-7, %col-sm-7, %col-xs-12;
            }
        }
    }

    .ml-item-list-sources {
        margin-top: 4rem;
        margin-bottom: 2rem;

        li {
            @extend %col-md-4, %col-xs-12;

            a {
                color: white;
                text-decoration: none;
                display: block;

                .source-summary-title {
                    font-size: rem-calc(24);
                    margin-bottom: .5rem;
                }

                .source-summary-subtitle {
                    font-size: rem-calc(21);
                    line-height: 1.7rem;
                    margin-bottom: .5rem;
                }

                .source-summary-body {
                    clear: $secondary-color;
                }

                &:hover {
                    opacity: .8;
                }
            }

            @media screen and (max-width: $viewport-lg) {

                a {

                    .source-summary-title {
                        font-size: rem-calc(20);
                    }

                    .source-summary-subtitle {
                        font-size: rem-calc(17);
                        line-height: 1.4rem;
                    }
                }
            }

            @media screen and (max-width: $viewport-md) {
                margin-bottom: 2rem;
            }
        }

        @media screen and (max-width: $viewport-md) {
            padding-left: 8rem;
            padding-right: 8rem;
        }

        @media screen and (max-width: $viewport-sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}