// Visibility Classes
// - - - - - - - - - - - - - - - - - - - - - - - - -

// $include-html-visibility-classes: $include-html-classes;
// $include-table-visibility-classes: true;
// $include-legacy-visibility-classes: true;
// $include-accessibility-classes: true;

//@import "../../web_modules/foundation/scss/foundation/components/visibility";

%visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
* Extends the .visuallyhidden class to allow the element to be focusable
* when navigated to via the keyboard: h5bp.com/p
*/

%visuallyhidden-focusable {
    @extend %visuallyhidden;
    &:active,
    &:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}

// Cancels the .visuallyhidden styles
%visible {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.accessibility-text {
    @extend %visuallyhidden;
}

.js {
    .is-hidden {
        display: none !important;
    }
}

.no-js {
    .button.is-hidden-no-js {
        display: none !important;
    }
}