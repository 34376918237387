.pg-login {
    background-color: $primary-color;
    
    .at-menu {
        a {
            color: $secondary-color;
        }
    }
    
    .at-page-header {
        padding-left: 8rem;
        padding-right: 8rem;
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 4rem;
        color: $secondary-color;
        
        .page-header-title {
            font-size: rem-calc(40);
        }
        
        .page-header-subtitle {
            font-size: rem-calc(20);
            line-height: rem-calc(28);
            
            a {
                color: $light-green;
                font-style: italic;
            }
        }
        
        @media screen and (max-width: $viewport-lg) {
            padding-left: 2rem;
            padding-right: 2rem;
        }
        
        @media screen and (max-width: $viewport-md) {
            margin-top: 2rem;
            
            .page-header-title {
                font-size: rem-calc(30);
            }
            
            .page-header-subtitle {
                font-size: rem-calc(18);
                line-height: rem-calc(24);
            }
        }
        
        @media screen and (max-width: $viewport-sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    
    .ml-form {
        
        form {
            
            .form-title {
                text-align: center;
                color: $secondary-color;
                margin-bottom: 2rem;
                font-style: italic;
            }
        
            .form-group-wrapper {
                display: block;
                text-align: center;

                .form-group {
                    display: inline-block;
                    padding: 0 .5rem;
                    text-align: left;
                    vertical-align: text-bottom;

                    label {
                        color: $secondary-color;
                        margin-bottom: .5rem;
                        font-style: italic;
                        font-size: 1.2rem;
                    }

                    input {
                        margin-bottom: 0;
                        width: 16rem;

                        &:focus {
                            outline: none;
                        }
                    }

                    @media screen and (max-width: $viewport-sm) {
                        text-align: center;
                        margin-bottom: 1rem;

                        input {
                            margin: 0 auto;
                        }
                    }

                    @media screen and (max-width: $viewport-xs) {

                        input {
                            width: 100%;
                        }

                        .button {
                            width: 100%;
                        }
                    }
                }
            }
            
            &.login-error {
                animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                perspective: 1000px;
            }

            @keyframes shake {
              10%, 90% {
                transform: translate3d(-1px, 0, 0);
              }

              20%, 80% {
                transform: translate3d(2px, 0, 0);
              }

              30%, 50%, 70% {
                transform: translate3d(-4px, 0, 0);
              }

              40%, 60% {
                transform: translate3d(4px, 0, 0);
              }
            }
        }
        
        &.password-request {
            
            @media screen and (max-width: $viewport-lg) {
                
                .form-group-wrapper {
                
                    .form-group {

                        input {
                            width: 11rem;
                        }
                    }
                } 
            }
            
            @media screen and (max-width: $viewport-sm) {
                
                .form-group-wrapper {
                    
                    .form-group {
                        
                        input,
                        .button {
                            width: 18rem;
                        }
                    }
                }
            }
            
            @media screen and (max-width: $viewport-xs) {
                
                .form-group-wrapper {
                    
                    .form-group {
                        
                        input,
                        .button {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    
    .faq-wrapper {
        text-align: center;
        
            a {
                color: $light-green;
                font-size: 1.2rem;
                font-style: italic;
            }
    }
    
    .request-password {
        
        margin-top: 3rem;
        display: none;
        
        .request-password-description {
            color: $secondary-color;
            text-align: center;
            font-size: 1.25rem;
            line-height: 1.75rem;
            margin-bottom: 2rem;
        }
        
    }
}