body {
    background-position: top center;
    background-repeat: no-repeat;
    
    &.bg-1 {
        background-image: url(../img/bg-1.png);
    }
    
    @media screen and (max-width: $viewport-sm) {
        background-size: cover;
    }
}