// Inline Lists
// - - - - - - - - - - - - - - - - - - - - - - - - -

$include-html-inline-list-classes: false;

// We use these to style lists
// $list-font-family: $paragraph-font-family;
// $list-font-size: $paragraph-font-size;
// $list-line-height: $paragraph-line-height;
// $list-margin-bottom: $paragraph-margin-bottom;
// $list-style-position: outside;
// $list-side-margin: 1.1rem;
// $list-ordered-side-margin: 1.4rem;
// $list-side-margin-no-bullet: 0;
// $list-nested-margin: rem-calc(20);
// $definition-list-header-weight: $font-weight-bold;
// $definition-list-header-margin-bottom: .3rem;
// $definition-list-margin-bottom: rem-calc(12);


// We use this to control the margins and padding of the inline list.
// $inline-list-top-margin: 0;
// $inline-list-opposite-margin: 0;
// $inline-list-bottom-margin: rem-calc(17);
// $inline-list-default-float-margin: rem-calc(-22);
// $inline-list-default-float-list-margin: rem-calc(22);

// $inline-list-padding: 0;

// We use this to control the overflow of the inline list.
// $inline-list-overflow: hidden;

// We use this to control the list items
// $inline-list-display: block;

// We use this to control any elements within list items
// $inline-list-children-display: block;

// @import "../../web_modules/foundation/scss/foundation/components/inline-lists";

%floated-list {
    @extend %clearfix;
    @extend %unstyled-list;
}

%flex-list {
    @extend %unstyled-list;
    @extend %around-xs;
    display: flex;
}

%unstyled-list {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

%inline-list {
    @extend %unstyled-list;
    > li {
        display: inline-block;
        margin-right: .5rem;
        &:last-of-type {
            margin-right: 0;
        }
    }
}

%piped-list {
    @extend %floated-list;
    > li {
        float: left;
        border-right: 1px solid;
        margin-right: .25rem;
        padding-right: .3rem;
        &:last-child {
            border-right: 0;
        }
    }
}

%pilcrow-list {
    @extend %inline-list;
    > li {
        margin-right: .25rem;
        
        &:after {
            content: '¶';
            display: inline-block;
            color: tint($primary-color, 30);
            margin-left: .35rem;
        }
        
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

%underlined-elements-list {
    @extend %unstyled-list;
    > li {
        border-bottom: 1px solid $primary-color-tint-2;
        padding-bottom: .5rem;
        padding-top: .5rem;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
}

%underlined-list {
    @extend %inline-list;
    margin-top: -1rem;
    > li {
        margin-top: 1rem;
        a {
            padding-bottom: .7rem;
            border-bottom: 5px solid transparent;
            @media #{$small-only} {
                padding-bottom: .2rem;
                border-bottom: 3px solid transparent;
            }
            //@extend %dull-link;
            @include default-transition('border');
            &:hover,
            &:focus,
            &:active {
                border-bottom-color: $gray4;
            }
        }
        &.is-active {
            a {
                border-bottom-color: $gray4;
            }
        }
    }
    &.item-sections-list {
        @extend %h5-size;
    }
}

dt,
dd {
    display: inline;
}

dd {
    margin-left: 0;
}