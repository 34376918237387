.pg-sources {

    .ml-item-list-sources {
        background-color: $secondary-color;
        padding-bottom: 2rem;

        li {
            @extend %col-md-12, %col-xs-12;
            margin-bottom: 1rem;

            .source-summary-title {
                font-size: rem-calc(21);
                margin-bottom: .5rem;
            }

            .source-summary-subtitle {
                font-size: rem-calc(18);
                font-style: italic;
                margin-bottom: 1rem;
            }

            .source-summary-body {
                font-size: 1.2rem;

                .link-wrapper {
                    margin-top: 1rem;
                    text-align: right;
                    font-style: italic;

                    a {
                        @extend %icon-arrow-long-right, %icon-spacing-after;
                    }
                }
            }

            @media screen and (max-width: $viewport-md) {
                margin-bottom: 2rem;
            }
        }

        @media screen and (max-width: $viewport-xs) {
            padding-left: 1rem;
            padding-right: 1rem;

            li {
                .source-summary-body {
                    font-size: rem-calc(16);
                }
            }
        }
    }

    .at-source-summary {
        @extend %row;
        margin-bottom: 2rem;

        .source-summary-header {
            @extend %col-md-4, %col-xs-12;
        }

        .source-summary-body {
            @extend %col-md-8, %col-xs-12;
        }
    }
}