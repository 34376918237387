// Add percentage of white to a color
@function tint($color, $percent){
    @return mix(white, $color, $percent);
}

// Add percentage of black to a color
@function shade($color, $percent){
    @return mix(black, $color, $percent);
}

// $white       : #FFFFFF;
// $ghost       : #FAFAFA;
// $snow        : #F9F9F9;
// $vapor       : #F6F6F6;
// $white-smoke : #F5F5F5;
// $silver      : #EFEFEF;
// $smoke       : #EEEEEE;
// $gainsboro   : #DDDDDD;
// $iron        : #CCCCCC;
// $base        : #AAAAAA;
// $aluminum    : #999999;
// $jumbo       : #888888;
// $monsoon     : #777777;
// $steel       : #666666;
// $charcoal    : #555555;
// $tuatara     : #444444;
// $oil         : #333333;
// $jet         : #222222;
// $black       : #000000;

//From darker to lighter
$gray1: tint(#414042, 10%);
$gray2: tint(#414042, 20%);
$gray3: tint(#414042, 30%);
$gray4: tint(#414042, 40%);
$gray5: tint(#414042, 50%);
$gray6: tint(#414042, 60%);
$gray7: tint(#414042, 70%);
$gray8: tint(#414042, 80%);
$gray9: #d9d9d9;
$gray10: #f0f0f1;

// We use these as default colors throughout
$primary-color: #66615e;
$secondary-color: #f1f2f2;
$body-bg: #b2aba8;
$dark-green: #128d90;
$light-green: #a2e9f3;

$primary-color-tint-1: tint($primary-color, 30);
$primary-color-tint-2: tint($primary-color, 60);
$secondary-color-shade-1: shade($secondary-color, 20%);
$alert-color: #9e3039;
$success-color: #43AC6A;
$warning-color: #f08a24;
$info-color: #a0d3e8;
$body-font-color: $primary-color;