.ml-site-footer {
    background-color: $primary-color;
    padding-top: 3rem;
    padding-bottom: 6rem;
    margin-top: 3rem;

    .at-footer-menu {
        ul {
            @extend %pilcrow-list;
            text-align: center;
            font-family: $body-font-family-sc;

            li {
                margin-bottom: .75rem;

                a {
                    color: $secondary-color;
                    text-decoration: none;

                    &:hover {
                        color: $light-green;
                    }
                }
            }
        }
    }

    .ml-item-list-footer-logos {

        > ul {
            display: flex;
            justify-content: center;

            li {
                margin: 0 $gutter-width $gutter-width 0;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

    }
}