.at-newspaper-number-summary {
    @extend %row;

    .newspaper-number-summary-header {
        @extend %col-md-10, %col-xs-8;
        font-size: 1.2rem;

        .newspaper-number-title {
            font-size: 1.5rem;
            margin-bottom: .5rem;
            font-weight: $font-weight-bold;
            margin-top: 1.3rem;
        }

        .newspaper-number-info {

            .newspaper-number-name {
                margin-bottom: 0;
                font-size: 1.5rem;
                color: $dark-green;
            }

            .newspaper-number-meta {
                @extend %pilcrow-list;
            }
        }
    }

    .newspaper-number-summary-section {
        font-size: 1.2rem;

        .newspaper-number-section-title {
            margin-top: 1.3rem;
            font-size: 1.2rem;
        }

        .newspaper-number-section-body {
            margin-bottom: 1rem;
        }
    }

    @media screen and (max-width: $viewport-sm) {

        .newspaper-number-summary-section {

            .newspaper-number-section-title {
                margin-top: 0;
            }
        }
    }
}