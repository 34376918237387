.at-secondary-menu {
    background-color: $primary-color;
    font-family: $body-font-family-sc;
    text-align: right;
    padding-top: .5rem;
    padding-bottom: .5rem;

    .language-menu {
        display: inline-block;
        margin-right: 1rem;

        ul {
            @extend %pilcrow-list;
            margin: 0;

            a {
                color: $secondary-color;
                text-decoration: none;

                &:hover,
                &:active {
                    color: $light-green;
                }
            }
        }
    }

    .access-menu {
        display: inline-block;
        line-height: 1.75rem;

        a {
            color: $secondary-color;
            text-decoration: none;
            margin-left: 1rem;

            &:hover {
                color: $light-green;
            }
            
            &.logout {
                @extend %icon-switch;
                font-size: 1.1rem;
            }
            
            &.settings {
                @extend %icon-gear;
                font-size: 1.25rem;
            }
        }
    }
}